import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ValidationKYC} from "../KYC/ValidationKYC";
import {Balance} from "../MainDashboard/Balance";
import {dateToString, formatCurrency, hideModal, isEmpty, loadProfile, showModal} from "../../util/commonFunctions";
import {Growl} from "primereact/growl";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {MERCHANT_KEY} from "../../util/constants";
import {Calendar} from "primereact/calendar";
import {MultiSelect} from "primereact/multiselect";
import {Messages} from "primereact/messages";
import {ProgressSpinner} from "primereact/progressspinner";

export class SendRequestFunds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amountToTransfer: '',
            sender_account: '',
            receiver_account: '',
            preferredCurrency: 'USD',
            currencies:[{label: 'Select Currency', value: ''},
                {label: 'USD', value: 'USD'}],
            rowFrom: [],
            wallet_reference: '',
            dataTableSelection: {},
            rowTo: [],
            actionsDetailTabView: false,
            addAccountDialogDisplay: false,
            requestFundsDialogDisplay: false,
            transferDialogDisplay: false,
            chargedYet: true,
            activeTabViewIndex: 1,
            dataTableDeposit: [],
            dataTableDepositSelected: {},
            dataTableTransfer: [],
            dataTableTransferSelected: {},
            dataTablePaypot: [],
            dataTablePaypotSelected: {},
            dataTableBestTransOrigin: [],
            dataTableBestTrans: [],
            dataTableBestTransSelected: {},
            rangeDate: null,
            selectedTransactionType: null,
            selectMemo: 'test',
            totalBalance: localStorage.getItem("totalBalance"),
            pendingBalance: localStorage.getItem("pendingBalance"),
            walletName: localStorage.getItem("walletName")
        }
        this.transaction_type = [
            {label: 'Paid someone via ACH?', value: 'Fund ACH (Origination)'},
            {label: 'P2P Sent/Received', value: 'Fund Transfer'},
            {label: 'Load Card', value: 'Load Card'},
            {label: 'Paid Invoice', value: 'Redeem Transfer'},
        ];

        this.backendService = new BackendService();
        this.loadAccounts = this.loadAccounts.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.addAccountSubmitHandler = this.addAccountSubmitHandler.bind(this);
        this.requestFundsSubmitHandler = this.requestFundsSubmitHandler.bind(this);
        this.transferSubmitHandlerNew = this.transferSubmitHandlerNew.bind(this);
        this.showActionsDetailTabView = this.showActionsDetailTabView.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onTransactionTypeChange = this.onTransactionTypeChange.bind(this);
        this.mergeCatalogs = this.mergeCatalogs.bind(this);
        this.onChangeFrom = this.onChangeFrom.bind(this);
        this.onChangeTo = this.onChangeTo.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.loadMemos = this.loadMemos.bind(this);
        //this.filterList = this.filterList.bind(this);
    }

    componentDidMount() {
        const self = this;
        this.initialLoad(self);
        this.loadMemos();
    }

    loadMemos() {
        this.backendService.getCatalogs().then(resp => {
            if (resp.status === 'success') {
                const catMemos = [];
                resp.catMemos.forEach(element => catMemos.push({label: element[1], value: element[0]}));
                this.setState({catMemos});
            }
        }).catch(error => {
            console.log("error: ", error);
            this.growl.show({severity: 'error', summary: 'Error', detail: "Error load catalogs"});
        });
    }

    initialLoad(self) {
        loadProfile(self, self.backendService, null).then(resp => {
            if (resp !== null) {
                this.loadAccounts(resp, self);
            }
        });
    }

    mergeCatalogs() {
        const temp = this.state.rowTo.map(item => {
            return {
                provider_accountid: item.id,
                label: item.alias
            };
        });
        const rowTo2= this.state.rowFrom.concat(temp);
        this.setState({rowTo2});
    }

    loadAccounts = (resp, self) => {
        const profileData = resp;
        if (profileData !== null) {
            profileData.array_accounts.forEach(function (element) {
                if (element.dlast_balance != null) {
                    element.dlast_balance = element.dlast_balance.substring(0, 10);
                }
            });
            const ROWFROM = profileData.array_accounts;
            let totalBalance, pendingBalance, walletName;
            ROWFROM.forEach(e => {
                walletName = e.label;
                totalBalance = formatCurrency(e.last_balance);
                pendingBalance = formatCurrency(e.pending);
                this.setState({totalBalance: totalBalance, pendingBalance: pendingBalance, walletName: walletName})
            });
            ROWFROM.forEach(e => e.last_balance = formatCurrency(e.last_balance));
            self.setState({rowFrom: ROWFROM});
            //load account 0
            if(ROWFROM.length > 0){
                const row = {}
                row.data =ROWFROM[0]
                this.setState({dataTableSelection: row.data});
                this.showActionsDetailTabView(row);
            }
            self.currentRows = ROWFROM.slice(self.indexOfFirstRow, self.indexOfLastRow);
        }
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
        }
        this.backendService.getAccountList(params).then(resp => {
            if (resp.status === 'Success') {
                const rowTo = [];
                resp.data.forEach(element => {
                    element.alias =  element.id + ' - ' + element.alias;

                    rowTo.push(element)
                });
                this.setState({rowTo});
                this.mergeCatalogs();
            } else {
                this.messages.show({severity: 'warning', summary: 'Warning', detail: "Error in Service"});
            }
        }).catch(error => {
            if (this.messages !== null) {
                this.messages.show({
                    severity: 'warning',
                    summary: 'Warning',
                    detail: `Error in Service recipients list`
                });
            }
        });

    };

    addAccountSubmitHandler = e => {
        e.preventDefault();
        const {wallet_reference} = this.state;
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
            alias: wallet_reference,
            provider_userid: profileData.profile.provider_userid,
        }
        const self = this;
        self.backendService.addAccountBest(params).then(resp => {
            if (resp.status === 'Success') {
                this.initialLoad(self);
                this.setState({
                    wallet_reference: '',
                    addAccountDialogDisplay: false,
                });
            } else {
                self.messages.show({severity: 'error', summary: 'Error', detail: `Error in Service Create New Sub-Wallet`});
            }
        }).catch(error => {
            if (self.messages !== null) {
                self.messages.show({severity: 'error', summary: 'Error', detail: `Error in Service Create New Sub-Wallet`});
            }
        });
    };

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="fa fa-paper-plane-o"
                    //onClick={() => this.showActionsDetailTabView(rowData) }
                        className="p-button-rounded p-button-help p-button-outlined"/>
            </React.Fragment>
        );
    }

    confirmationDialog = (nameModal, showModal, action) => {
        const {
            wallet_reference, preferredCurrency,
            amountToTransfer, sender_account, receiver_account
        } = this.state;
        let showConfirm = true;
        //validate Create New Sub-Wallet
        if (nameModal === 'addAccountDialogDisplay') {
            if (isEmpty(wallet_reference) || isEmpty(preferredCurrency)) {
                showConfirm = false;
                this.growl.show({severity: 'error', summary: 'Error', detail: 'Please fill all fields.', life: 4500});
            }
        } else if (nameModal === 'requestFundsDialogDisplay') { //validate requestFunds
            if (isEmpty(amountToTransfer) || isEmpty(sender_account) || isEmpty(receiver_account) ||
                isEmpty(wallet_reference)) {
                showConfirm = false;
                this.growl.show({severity: 'error', summary: 'Error', detail: 'Please fill all fields.', life: 4500});
            }
        }
        this.setState({
            [showModal]: showConfirm,
            actionToConfirm: action
        })
    }

    requestFundsSubmitHandler = e => {
        e.preventDefault();
        const {amountToTransfer, sender_account, receiver_account, wallet_reference} = this.state;
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
            request_account: receiver_account?.id,
            sender_account_id: sender_account?.id,
            reference: wallet_reference,
            amount: amountToTransfer,
            test: true
        }
        console.log('params: ', params)
        const self = this;
        this.backendService.bestRequestFunds(params).then(resp => {
            if (resp.status === 'Success') {
                this.initialLoad(self);
                this.setState({
                    requestFundsDialogDisplay: false,
                    sender_account: '',
                    receiver_account: '',
                    amountToTransfer: '',
                    wallet_reference: '',
                });
                this.messages.show({severity: 'success', summary: 'Success', detail: 'Coin Moved Successfully!'});
            } else {
                this.growl.show({severity: 'warning', summary: 'Warning', detail: 'Error in Service request funds'});
            }
        }).catch(error => {
            this.setState({requestFundsDialogDisplay: false});
            const errorObjectShow = `CATCH: ${JSON.stringify(
                error.response.data.message,
            )}`;
            this.growl.show({
                severity: 'warning',
                summary: 'Warning',
                detail: `Error in Service recipients list ${errorObjectShow}`
            });
        });
    };

    transferSubmitHandlerNew = e => {
        e.preventDefault();

        const {amountToTransfer, sender_account, receiver_account, wallet_reference} = this.state;

        if (amountToTransfer === '' || sender_account === '' || receiver_account === '' || wallet_reference === '') {
            this.growl.show({severity: 'error', summary: 'Error', detail: 'Please give a valid info!'});
        } else {
            //const profileData = JSON.parse(localStorage.getItem('profile'));
            const params = {
                //merchant_key: MERCHANT_KEY,
                //username: profileData.email,
                sender_account: sender_account.provider_accountid,
                receiver_account : receiver_account.provider_accountid.toString(),
                amount: amountToTransfer,
                reference: wallet_reference,
                type_memo: this.state.selectMemo
                //test: true
            }
            this.backendService.postTransferAccountBest(params).then(resp => {
                if (resp.status === 'Success') {
                    loadProfile(this, this.backendService, null).then(resp => {
                        if (resp !== null) {
                            this.loadAccounts(resp, this);
                        }
                    });
                    this.setState({
                        transferDialogDisplay: false,
                        amountToTransfer: '',
                        sender_account: '',
                        receiver_account: '',
                        wallet_reference: '',
                    });
                    this.messages.show({severity: 'success', summary: 'Success', detail: 'Coin Moved Successfully!'});
                } else {
                    this.growl.show({severity: 'warning', summary: 'Warning', detail: 'Error in Service'});
                }
            }).catch(error => {
                this.setState({transferDialogDisplay: false});
                const errorObjectShow = `CATCH: ${JSON.stringify(
                    error.response.data.message,
                )}`;
                this.growl.show({
                    severity: 'warning',
                    summary: 'Warning',
                    detail: `Error in Service recipients list ${errorObjectShow}`
                });
            });
        }
    };

    showActionsDetailTabView(rowData) {
        this.setState({chargedYet: false});
        rowData = rowData === undefined ? this.state.dataTableSelection : rowData.data;
        const params = {
            account_id: rowData.provider_accountid,
            date_ini: '',
            date_end: '',
        }
        if(this.state.rangeDate !== null){
            params.date_ini = dateToString(this.state.rangeDate[0]);
            params.date_end = dateToString(this.state.rangeDate[1]);
        }
        // eslint-disable-next-line
        this.setState({actionsDetailTabView: true});
        if (params.date_ini !== '') {
            this.backendService.postReportHistory(params).then(resp => {
                if (resp.status === 'success') {
                    const lstDeposit = resp.data.lstDeposit !== null ? resp.data.lstDeposit : [];
                    const lstWithdraw = resp.data.lstWithdraw !== null ? resp.data.lstWithdraw : [];
                    const lstPaidOut = resp.data.lstPaidOut !== null ? resp.data.lstPaidOut : [];
                    //const lstBestTrans = resp.data.lstBestTrans !== null ? resp.data.lstBestTrans : [];
                    lstDeposit.forEach(function (element) {
                        if (element.dcreated != null) {
                            element.dcreated = element.dcreated.substring(0, 10);
                        }
                    });
                    lstWithdraw.forEach(function (element) {
                        if (element.dcreated != null) {
                            element.dcreated = element.dcreated.substring(0, 10);
                        }
                    });
                    lstPaidOut.forEach(function (element) {
                        if (element.dcreated != null) {
                            element.dcreated = element.dcreated.substring(0, 10);
                        }
                    });
                    /*lstBestTrans.forEach(function (element) {
                        if (element.TransactionDate != null) {
                            element.TransactionDate = element.TransactionDate.substring(0, 10);
                        }
                    });*/
                    this.setState({
                        dataTableDeposit: lstDeposit,
                        dataTableTransfer: lstWithdraw,
                        dataTablePaypot: lstPaidOut,
                        //dataTableBestTransOrigin: lstBestTrans,
                        dataTableBestTrans: lstWithdraw,
                        chargedYet: true,
                    });
                }

            }).catch(error => {
                this.growl.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: `Error in Service recipients list ${error}`
                });
            });
        } else {
            this.setState({chargedYet: true})
        }
    }

    changeHandler = event => {
        const value = event.target.value;
        if (event.target.name === 'amountToTransfer') {
            if (value === "" || Number.isInteger(+value)) {
                this.setState({[event.target.name]: value});
            }
        } else {
            this.setState({[event.target.name]: event.target.value});
        }
    }

    onChangeFrom(event) {
        if (event.value.provider_accountid !== this.state.receiver_account.provider_accountid) {
            const sender_account = event.value;
            this.setState({sender_account});
        } else {
            this.growl.show({severity: 'info', summary: 'Info', detail: `You cannot send funds to same wallet`});
        }
    }
    onChangeTo(event) {
        if (event.value.provider_accountid !== this.state.sender_account.provider_accountid) {
            const receiver_account = event.value;
            this.setState({receiver_account});
        } else {
            this.growl.show({severity: 'info', summary: 'Info', detail: `You cannot send funds to same wallet`});
        }
    }

    /*filterList(event) {
            var array = event.value;
            var indice = this.state.rowFrom2.indexOf(array);
            var removeAccount = this.state.rowFrom2.splice(indice, 1);
            console.log(removeAccount)
        }*/

    itemTemplate(option) {
        return (
            <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {option.label}
                    <span style={{color: "#E0C46C"}}>{option.last_balance}</span>
                </div>
            </React.Fragment>
        );
    }


    onTransactionTypeChange(event) {
        if(event.value.length === 0){
            this.setState({dataTableBestTrans: this.state.dataTableBestTransOrigin});
        } else {
            const filter = [];
            this.state.dataTableBestTransOrigin.forEach(e => {
                event.value.forEach(v => {
                    if(v === e.TransactionDesc)
                        filter.push(e)
                });
            });
            this.setState({dataTableBestTrans: filter});
        }
        this.setState({selectedTransactionType: event.value});
    }

    render() {
        const transactionType = <MultiSelect placeholder="Type" value={this.state.selectedTransactionType}
                                             style={{width: '100%', marginTop: 6}}
                                             options={this.transaction_type} onChange={this.onTransactionTypeChange} />;

        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={hideModal(nameModal, this)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processService}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processService}/>
            </div>
        );

        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw', background: "#1c2155"}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={hideModal('displayConfirm', this)}>
                <p>Are you sure, continue</p>
            </Dialog>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={hideModal(nameModal, this)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction}/>
            </div>
        );

        const addAccountDialog = (
            <Dialog header="Create New Sub-Wallet" visible={this.state.addAccountDialogDisplay} modal={true}
                    style={{width: '450px', background: "#1c2155"}}
                    contentStyle={{height: 170}}
                    footer={dialogFooter('addAccountDialogDisplay', this.addAccountSubmitHandler, 'Add')}
                    onHide={hideModal('addAccountDialogDisplay', this)}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="alias" className="p-col-fixed" style={{width: '120px'}}>Alias:</label>
                        <div className="p-col">
                            <InputText value={this.state.wallet_reference}
                                       placeholder="Account name"
                                       name="wallet_reference"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="account" className="p-col-fixed" style={{width: '120px'}}>Currency:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                options={this.state.currencies}
                                value={this.state.preferredCurrency}
                                placeholder="Select currency"
                                name="preferredCurrency"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const requestFundsDialog = (
            <Dialog header="Request Funds" visible={this.state.requestFundsDialogDisplay} modal={true}
                    style={{width: '550px', background: "#1c2155"}}
                    contentStyle={{height: 250}}
                    footer={dialogFooter('requestFundsDialogDisplay', this.requestFundsSubmitHandler, 'SEND')}
                    onHide={hideModal('requestFundsDialogDisplay', this)}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="from" className="p-col-fixed" style={{width: '160px'}}>From:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                options={this.state.rowTo}
                                optionLabel="alias"
                                value={this.state.sender_account}
                                placeholder="Sender account"
                                name="sender_account"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="to" className="p-col-fixed" style={{width: '160px'}}>To:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                options={this.state.rowFrom}
                                itemTemplate={this.itemTemplate}
                                optionLabel="label"
                                value={this.state.receiver_account}
                                placeholder="Select receiver account"
                                name="receiver_account"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="amountToTransfer" className="p-col-fixed"
                               style={{width: '160px'}}>Amount:</label>
                        <div className="p-col">
                            <InputText value={this.state.amountToTransfer}
                                       placeholder="Amount"
                                       type="number"
                                       name="amountToTransfer"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Reference:</label>
                        <div className="p-col">
                            <InputText value={this.state.wallet_reference}
                                       placeholder="Reference"
                                       name="wallet_reference"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );
        const dateTemplate = (rowData) => {

            let date = rowData.d_created.split("T")[0];
            return <span>{date}</span>;
        };
        const formatCurrency = (field) => {
            return (rowData) => parseFloat(rowData[field]).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
        const tabViewDetail = (
            <div className="card card-w-title ">
                <div className="p-grid">
                    <div className="p-col-10">
                        <h2>Wallet Transaction Activity</h2>
                    </div>
                    <div className="p-col-2 ta-right">
                        <i className="pi pi-times" style={{'fontSize': '1.5em', color: '#f7ab32', cursor: 'pointer'}}
                           onClick={hideModal('actionsDetailTabView', this)}/>
                    </div>
                    <div className="p-col-12 mt-20">
                        <div className="grid">
                            {this.state.chargedYet ? '' :
                                <div className="col-9 ta-right" style={{position: "relative", marginTop: -60, paddingRight: 20,}}>
                                    <ProgressSpinner style={{width: '50px', height: '50px'}}
                                                     animationDuration=".7s"/>
                                </div>
                            }
                        </div>
                        <div className="p-fluid p-grid p-formgrid">
                            <div className="p-col-12 p-sm-4 p-xl-4">
                                <Calendar id="rangeDate"
                                          placeholder="Rage Date"
                                          dateFormat="yy-mm-dd"
                                          name="rangeDate"
                                          selectionMode="range"
                                          value={this.state.rangeDate}
                                          onChange={this.changeHandler}
                                          showIcon monthNavigator/>
                            </div>
                            <div className="p-col-12 p-sm-4 p-xl-2">
                                <Button label="Search"  icon="pi pi-search" onClick={() => this.showActionsDetailTabView()}/>
                                <small id="username2-help" className="p-error p-d-block">*Enter Date Range In To Calendar</small>
                            </div>
                        </div>
                        <DataTable value={this.state.dataTableBestTrans} selectionMode="single" paginator={true}
                                   rows={20} className="mt-20" responsive={true}
                                   selection={this.state.dataTableBestTransSelected}
                                   onSelectionChange={event => this.setState({dataTableBestTransSelected: event.value})}>
                            <Column field='id' header='Transaction Number' filter={true} className="ta-center"
                                    filterPlaceholder="Transaction Number"/>
                            <Column field='d_created' header='Date' body={dateTemplate} className="ta-center" filter={true}
                                    filterPlaceholder="Date" sortable={true}/>
                            <Column field='type_transfer' header='Description' className="ta-center"
                                    filter={true} filterElement={transactionType}  sortable={true}/>
                            <Column field='reference' header='Reference' className="ta-center"
                                    filter={true} filterPlaceholder="Reference" sortable={true}/>
                            <Column field='amount' header='Amount' body={formatCurrency('amount')} className="ta-center" filter={true}
                                    filterPlaceholder="Amount" sortable={true}/>
                            <Column field='status_transfer' header='Status'className="ta-center" filter={true}
                                    filterPlaceholder="Balance" sortable={true}/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );

        const transferDialog = (
            <Dialog header="Send Funds" visible={this.state.transferDialogDisplay} modal={true}
                    style={{width: '550px', background: "#1c2155"}}
                    contentStyle={{height: 370}}
                    footer={dialogFooter('transferDialogDisplay', this.transferSubmitHandlerNew, 'Send')}
                    onHide={hideModal('transferDialogDisplay', this)}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="from" className="p-col-fixed" style={{width: '160px'}}>From:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                itemTemplate={this.itemTemplate}
                                options={this.state.rowFrom}
                                optionLabel="label"
                                value={this.state.sender_account}
                                placeholder="Sender account"
                                name="sender_account"
                                onChange={this.onChangeFrom} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="to" className="p-col-fixed" style={{width: '160px'}}>To:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                itemTemplate={this.itemTemplate}
                                options={this.state.rowTo2}
                                optionLabel="label"
                                value={this.state.receiver_account}
                                placeholder="Select receiver account"
                                name="receiver_account"
                                onChange={this.onChangeTo} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="to" className="p-col-fixed" style={{width: '160px'}}>Type:</label>
                        <div className="p-col">
                            <Dropdown options={this.state.catMemos}
                                //itemTemplate={this.itemTemplate}
                                      value={this.state.selectMemo}
                                      name="selectMemo"
                                      placeholder="Type"
                                      onChange={this.changeHandler}
                                      autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="amountToTransfer" className="p-col-fixed"
                               style={{width: '160px'}}>Amount:</label>
                        <div className="p-col">
                            <InputText value={this.state.amountToTransfer}
                                       placeholder="Amount"
                                       type="number"
                                       name="amountToTransfer"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="comment" className="p-col-fixed" style={{width: '160px'}}>Comment or
                            reference:</label>
                        <div className="p-col">
                            <InputText value={this.state.wallet_reference}
                                       placeholder="Add comment"
                                       name="wallet_reference"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-8 p-md-8">
                                <h2 className="mb-20">Account List</h2>
                            </div>
                            <div className="p-col-12 p-lg-4 p-md-4 ta-right">
                                <Balance totalBalance={this.state.totalBalance}
                                         pendingBalance={this.state.pendingBalance} walletName={this.state.walletName}/>
                            </div>
                            <div className="p-col-12 text-center">
                                <Messages ref={(el) => this.messages = el}></Messages>
                                {/*<Button label="Create New Sub-Wallet" onClick={showModal('addAccountDialogDisplay', this)}/>*/}
                                {/*<Button label="Request Funds" onClick={showModal('requestFundsDialogDisplay', this)}/>*/}
                                <Button label="Send Funds" icon="fa fa-money" onClick={showModal('transferDialogDisplay', this)} />
                                <Button label="Add a Friend Wallet" icon="fa fa-user-plus" onClick={() => this.props.history.push("/registerAccount")}/>
                            </div>
                            <div className="p-col-12">
                                <DataTable value={this.state.rowFrom} selectionMode="single" paginator={true} rows={10}
                                           selection={this.state.dataTableSelection}
                                           onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                                           responsive={true}>
                                    <Column field='label' header='NAME'/>
                                    <Column field='last_balance' header='AVAILABLE' className="ta-center"/>
                                    <Column field='dlast_balance' header='UPDATED AT' className="ta-center"/>
                                </DataTable>
                            </div>
                            {confirmDialog}
                            {addAccountDialog}
                            {requestFundsDialog}
                            {transferDialog}
                        </div>
                    </div>
                    {this.state.actionsDetailTabView && tabViewDetail}
                </div>
                <ValidationKYC history={this.props.history}/>
            </div>
        )
    }
}
