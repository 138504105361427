import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@material-ui/core";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/components/button/Button";
import {isEmpty, loadProfile, logOut, valueIsNumber} from "../../util/commonFunctions";
import {ValidationKYC} from "../KYC/ValidationKYC";

export class New_Top_Up_Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            isReady: false,
            payment_type: 'ach',
            catLinkCards: [],
            accounts: [],
            account: null,
            linkCardSelected: null,
            card_reference: '',
            catAccountCards: [],
            accountCardSelected: null,
            showSendAccount: false,
            openConfirmation: false,
            coin_amount: '',
            calculatedFee: '',
            calculatedTotal: '',
            submitFormProcess: false,
            selectMemo: 'test',
        };
        this.submitHandler = this.submitHandler.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.sendToAddAccount = this.sendToAddAccount.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.loadMemos = this.loadMemos.bind(this);
        this.backendService = new BackendService();
    }

    componentDidMount() {
        const self = this;
        loadProfile(this, this.backendService, null).then(resp => {
            if (resp !== null) {
                this.setState({profileData: resp});
                this.loadLinkCards(resp, self);
                this.loadAccountCards(resp, self);
                this.loadMemos();
                const accounts = resp.array_accounts;
                this.setState({accounts, account: accounts[0]});
            }
        });
    }
    loadLinkCards(data, self) {
        const catLinkCards = [];
        if (data?.accounts?.USD?.provider_accountid !== null && data?.accounts?.USD?.provider_accountid !== undefined) {
            self.backendService.getListBestBanks(data.accounts?.USD?.provider_accountid).then(resp => {
                if (resp["status code"] === 200) {
                    if (resp.data) {
                        resp.data.forEach(element => {
                            element.LastFourDigitsCardNumber = "************" + element.LastFourDigitsCardNumber;
                            catLinkCards.push(element)
                        });
                        self.setState({catLinkCards});
                    }
                }
                self.setState({isReady: true});
            }).catch(error => {
                console.log("ERROR: ", error);
                if (error.response?.status === 401) {
                    logOut(self.props.history);
                }
                self.setState({isReady: true});
            });
        }
    }
    loadAccountCards(data, self) {
        const catAccountCards = [];
        if (data?.accounts?.USD?.provider_accountid !== null && data?.accounts?.USD?.provider_accountid !== undefined) {
            self.backendService.getListBanks(data.accounts?.USD?.provider_accountid).then(resp => {
                if (resp["status code"] === 200) {
                    if (resp.data) {
                        resp.data.forEach(element => {
                            element.VerifiedDate = element.VerifiedDate.substring(0, 10);
                            catAccountCards.push(element)
                        });
                    }
                    this.setState({catAccountCards, accountCardSelected: catAccountCards[0]});
                }
            }).catch(error => {
                console.log("ERROR: ", error);
                if (error.response?.status === 401) {
                    logOut(self.props.history);
                }
            });
        }
    }
    loadMemos() {
        this.backendService.getCatalogs().then(resp => {
            if (resp.status === 'success') {
                const catMemos = [];
                resp.catMemos.forEach(element => catMemos.push({label: element[1], value: element[0]}));
                this.setState({catMemos});
            }
        }).catch(error => {
            console.log("error: ", error);
            this.growl.show({severity: 'error', summary: 'Error', detail: "Error load catalogs"});
        });
    }
    submitHandler = e => {
        e.preventDefault();
        const {
            coin_amount,
            calculatedFee,
            calculatedTotal,
            payment_type,
            linkCardSelected,
            accountCardSelected,
            card_reference,
            account,
        } = this.state;
        if (payment_type === 'linkCard') {
            this.setState({submitFormProcess: true});
            if (this.validateForm()) {
                const params = {
                    account_id: account.provider_accountid,
                    payment_vault_id: linkCardSelected['PaymentVaultId'],
                    sub_amount: coin_amount,
                    fee: calculatedFee,
                    total_amount: calculatedTotal,
                    invoice_number: card_reference,
                }
                this.backendService.loadFundsTokenByLinkCard(params).then(resp => {
                    if (resp.status === 'success') {
                        this.growl.show({severity: 'success', summary: 'SUCCESS', detail: "Success", life: 4000});
                        setTimeout(() => {
                            this.props.history.push('/');
                        }, 2000);
                    } else {
                        this.growl.show({severity: 'error', summary: 'Error', detail: "Error load funds by card"});
                    }
                    this.setState({submitFormProcess: false});
                }).catch(error => {
                    this.setState({submitFormProcess: false});
                    console.error("error: ", error);
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Error load funds by card"});
                });
            }
        } else if (payment_type === 'ach') {
            this.setState({submitFormProcess: true});
            if (this.validateForm()) {
                const params = {
                    account_id: account.provider_accountid,
                    best_bank_name: accountCardSelected['BankAccountName'],
                    bank_account_token: accountCardSelected['BankAccountToken'],
                    sub_amount: coin_amount,
                    fee: calculatedFee,
                    total_amount: calculatedTotal,
                    invoice_number: card_reference,
                    type_memo: this.state.selectMemo
                }
                this.backendService.loadFundsTokenByAccountCard(params).then(resp => {
                    if (resp.status === 'success') {
                        this.growl.show({severity: 'success', summary: 'SUCCESS', detail: "Success", life: 4000});
                        setTimeout(() => {
                            this.props.history.push('/');
                        }, 2000);
                    } else {
                        this.growl.show({severity: 'error', summary: 'Error', detail: "Error load funds by card"});
                    }
                    this.setState({submitFormProcess: false});
                }).catch(error => {
                    this.setState({submitFormProcess: false});
                    console.error("error: ", error);
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Error in Top Up Account"});
                });
            }
        }
    }
    changeHandler = e => {
        const {errors} = this.state;
        if (errors[e.target.name] !== undefined) {
            errors[e.target.name] = undefined;
        }
        if (e.target.name === "coin_amount") {
            if (valueIsNumber(e.target.value)) {
                const value = parseFloat(e.target.value);
                let fee = value * 0.05;
                if (!valueIsNumber(value) || value === 0) {
                    fee = 0;
                }
                const total = value + fee ;
                this.setState({
                    [e.target.name]: e.target.value,
                    calculatedFee: fee,
                    calculatedTotal: total,
                });
            } else if (isEmpty(e.target.value)) {
                this.setState({
                    [e.target.name]: e.target.value,
                    calculatedFee: "",
                    calculatedTotal: "",
                });
            }
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
        this.setState({errors});
    };
    changeHandlerText = e => {
        const {errors} = this.state;
        if (errors[e.target.name] !== undefined) {
            errors[e.target.name] = undefined;
        }
        this.setState({
            errors,
            [e.target.name]: e.target.value,
        });
    };
    handleClose() {
        this.setState({openConfirmation: false, showSendAccount: false});
    }
    sendToAddAccount() {
        const {payment_type} = this.state;
        if (payment_type === 'linkCard') {
            this.props.history.push("/link_cards");
        } else if (payment_type === 'ach') {
            this.props.history.push("/bank_accounts");
        }
    }
    itemTemplate(option) {

        return (
            <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {option.label}
                    <span style={{color: "#E0C46C"}}>${option.last_balance}</span>
                </div>
            </React.Fragment>
        );
    }
    validateForm() {
        const {
            coin_amount,
            payment_type,
            linkCardSelected,
            accountCardSelected,
            account,
        } = this.state;
        const errors = {};
        let isValid = true;
        if (!valueIsNumber(coin_amount) && coin_amount < 1) {
            errors.coin_amount = "Amount is mandatory";
            isValid = false;
        }
        if (account === null) {
            errors.account = "Account is mandatory";
            isValid = false;
        }

        if (payment_type === 'linkCard') {
            if (linkCardSelected === null) {
                errors.linkCardSelected = "Card is mandatory";
                isValid = false;
            }
        } else if (payment_type === 'ach') {
            if (accountCardSelected === null) {
                errors.accountCardSelected = "Card is mandatory";
                isValid = false;
            }
        }
        this.setState({errors});
        return isValid;
    }

    render() {
        const {coin_amount,} = this.state;
        const linkCardForm = (
            <div className="p-col-12">
                <div className="p-grid p-fluid text-left">
                    <div className="p-col-12 p-lg-4 p-md-4">
                        <label>Card:*</label>
                        <Dropdown options={this.state.catLinkCards} value={this.state.linkCardSelected}
                                  optionLabel="PaymentVaultTitle" optionValue="PaymentVaultId"
                                  placeholder="Select a card"
                                  name="linkCardSelected"
                                  onMouseDown={() => {
                                      if (this.state.catLinkCards.length === 0) {
                                          this.setState({showSendAccount: true});
                                      }
                                  }}
                                  onChange={this.changeHandler} autoWidth={false}/>
                        {this.state.errors?.linkCardSelected !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.linkCardSelected}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-lg-4 p-md-4">
                        <label>Account List:*</label>
                        <Dropdown options={this.state.accounts}
                                  value={this.state.account}
                                  optionLabel="label" optionValue="provider_accountid"
                                  placeholder="Account"
                                  name="account"
                                  onMouseDown={() => {
                                      if (this.state.accounts.length === 0) {
                                          this.setState({showSendAccount: true});
                                      }
                                  }}
                                  onChange={this.changeHandler} autoWidth={false}/>
                        {this.state.errors?.account !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.account}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-lg-6 p-md-6">
                        <label>Reference</label>
                        <InputText
                            className="pd-11"
                            placeholder="Reference"
                            name="card_reference"
                            value={this.state.card_reference}
                            onChange={this.changeHandlerText}
                            onBlur={this.isDisabled}
                        />
                    </div>
                </div>
            </div>
        );
        const achForm = (
            <div className="p-col-12">
                <div className="p-grid p-fluid text-left">
                    <div className="p-col-12 p-lg-4 p-md-4">
                        <label>Bank account:*</label>
                        <Dropdown options={this.state.catAccountCards} value={this.state.accountCardSelected}
                                  optionLabel="BankAccountName" optionValue="BankAccountToken"
                                  placeholder="Select a bank account"
                                  name="accountCardSelected"
                                  onMouseDown={() => {
                                      if (this.state.catAccountCards.length === 0) {
                                          this.setState({showSendAccount: true});
                                      }
                                  }}
                                  onChange={this.changeHandler} autoWidth={false}/>
                        {this.state.errors?.accountCardSelected !== undefined ?
                            <small
                                className="p-messages p-messages-error">{this.state.errors?.accountCardSelected}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-lg-4 p-md-4">
                        <label>Account List:*</label>
                        <Dropdown options={this.state.accounts}
                                  itemTemplate={this.itemTemplate}
                                  value={this.state.account}
                                  optionLabel="label" optionValue="provider_accountid"
                                  placeholder="Account"
                                  name="account"
                                  onMouseDown={() => {
                                      if (this.state.accounts.length === 0) {
                                          this.setState({showSendAccount: true});
                                      }
                                  }}
                                  onChange={this.changeHandler} autoWidth={false}/>
                        {this.state.errors?.account !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.account}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-lg-4 p-md-4">
                        <label>Reference</label>
                        <InputText
                            className="pd-11"
                            placeholder="Reference"
                            name="card_reference"
                            value={this.state.card_reference}
                            onChange={this.changeHandlerText}
                            onBlur={this.isDisabled}
                        />
                    </div>
                    <div className="p-col-12 p-lg-4 p-md-4">
                        <label>Type </label>
                        <Dropdown options={this.state.catMemos}
                            //itemTemplate={this.itemTemplate}
                                  value={this.state.selectMemo}
                                  name="selectMemo"
                                  onChange={this.changeHandler}
                                  autoWidth={false}/>
                        {this.state.errors?.account !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.account}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-lg-4 p-md-4" style={{paddingTop: '35px'}}>
                        <Button label="Submit"
                                disabled={this.state.submitFormProcess}
                                onClick={() => {
                                    if (this.validateForm()) {
                                        this.setState({openConfirmation: true});
                                    }
                                }} type="button"
                                className="p-button-rounded p-button-primary"/>
                    </div>
                </div>
            </div>
        );
        const modalSendToAccount = (
            <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                    className="modal-dialog"
                    open={this.state.showSendAccount} onClose={this.handleClose}>
                <DialogTitle id="alert-dialog-title">You don't have Cards</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{color: "#ECECEC"}}>
                        Would you like to add {this.state.payment_type === 'linkCard' ? 'Credit/Debit Cards' : 'Bank Accounts'}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button label="Disagree" style={{width: 100}}
                            onClick={this.handleClose}
                            className="p-button-rounded p-button-secondary"/>
                    <Button label="Agree" style={{width: 100}}
                            onClick={e => {
                                this.handleClose();
                                this.sendToAddAccount(e);
                            }}
                            className="p-button-rounded p-button-primary"/>
                </DialogActions>
            </Dialog>
        );
        const modalConfirmation = (
            <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
                    className="modal-dialog"
                    open={this.state.openConfirmation} onClose={this.handleClose}>
                <DialogTitle id="alert-dialog-title">Top Up Account?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{color: "#ECECEC"}}>
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button label="Disagree" style={{width: 100}}
                            onClick={this.handleClose}
                            className="p-button-rounded p-button-secondary"/>
                    <Button label="Agree" style={{width: 100}}
                            onClick={e => {
                                this.handleClose();
                                this.submitHandler(e);
                            }}
                            className="p-button-rounded p-button-primary"/>
                </DialogActions>
            </Dialog>
        );

        return (
            <div className="p-grid dashboard">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12">
                    <div className="card" style={{padding: 40}}>
                        <form onSubmit={this.submitHandler}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <h1>Top up my Account</h1>
                                </div>
                                <div className="p-col-12">
                                    <FormControl component="fieldset" fullWidth>
                                        <RadioGroup aria-label="position" row
                                                    name="payment_type"
                                                    value={this.state.payment_type}
                                                    onChange={this.changeHandler}>
                                            {/*
                                            <FormControlLabel
                                                value="linkCard"
                                                control={<Radio color="primary"/>}
                                                label="Credit/Debit Card"
                                                labelPlacement="end"/>
                                            */}
                                            <FormControlLabel
                                                value="ach"
                                                control={<Radio color="primary"/>}
                                                label="ACH"
                                                style={{display: 'none'}}
                                                labelPlacement="end"/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-4 p-md-4">
                                            <label>Amount</label>
                                            <InputText className="pd-11"
                                                       placeholder="Amount"
                                                       name="coin_amount"
                                                       value={coin_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                                       onChange={this.changeHandler}
                                            />
                                            {this.state.errors?.coin_amount !== undefined ?
                                                <small
                                                    className="p-messages p-messages-error">{this.state.errors?.coin_amount}</small>
                                                : ""
                                            }
                                        </div>
                                        <div className="p-col-12 p-lg-4 p-md-4">
                                            <label>Fee</label>
                                            <InputText
                                                className="pd-11"
                                                placeholder="Fee"
                                                name="calculatedFee"
                                                value={this.state.calculatedFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="p-col-12 p-lg-4 p-md-4">
                                            <label>Total</label>
                                            <InputText
                                                className="pd-11"
                                                placeholder="Total"
                                                name="calculatedTotal"
                                                value={this.state.calculatedTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Top up with my Link Cards */}
                                {this.state.payment_type === 'linkCard' && this.state.isReady && linkCardForm}
                                {/* Top up with my new ACH */}
                                {this.state.payment_type === 'ach' && this.state.isReady && achForm}

                            </div>
                        </form>
                    </div>
                </div>
                {modalSendToAccount}
                {modalConfirmation}

                <ValidationKYC history={this.props.history}/>
            </div>
        )
    }

}
